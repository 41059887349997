<template>
    <!-- 询价中 -->
    <div id="enterpriseServiceInquiry">
        <div class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">提交时间</th>
                        <th class="text-center">项目名称</th>
                        <th class="text-center">抽成比例</th>
                        <th class="text-center">服务提供方</th>
                        <th class="text-center">用户信息</th>
                        <th class="text-center">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="list.length > 0">
                        <tr v-for="(log, index) in list" :key="index">
                            <td class="text-center date-td">
                                {{ log.createTime || '-' }}
                            </td>
                            <td class="text-center">
                                {{ log.itemName || '-' }}
                            </td>
                            <td class="text-center">
                                <template v-if="log.commissionScale">
                                    {{ log.commissionScale }}%
                                </template>
                                <template v-else> - </template>
                            </td>
                            <template>
                                <td class="text-center">
                                    <p style="margin-bottom: 0">
                                        {{ log.providerName || "物业" }}
                                    </p>
                                    <p style="margin-bottom: 0" v-if="log.providerContact">
                                        {{ log.providerContact || "" }}
                                    </p>
                                    <P v-if="log.providerPhone">
                                        {{ log.providerPhone || "" }}
                                    </P>
                                </td>
                            </template>
                            <td class="text-center">
                                <p style="margin-bottom: 0" v-if="log.userName">
                                    {{ log.userName }}
                                    【{{ log.userPhone }}】
                                </p>
                                <p style="margin-bottom: 0" v-if="log.userCompany">
                                    {{ log.userCompany }}
                                </p>
                                <p style="margin-bottom: 0" v-if="log.userCompanyAddr">
                                    {{ log.userCompanyAddr }}
                                </p>
                            </td>
                            <td class="text-center">
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        操作
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <a
                                                @click="querySigning(log.id)"
                                                style="width: 100%;"
                                            >
                                                签约项目
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                @click="noQuerySigning(log.id)"
                                                style="width: 100%;"
                                            >
                                                拒签项目
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <Pagination
                name="Pagination"
                component="Pagination"
            ></Pagination>
        </div>
        <!-- 点击签约项目的弹出框 -->
        <CSDialog
            dialogTitle="签约项目"
            dialogWidth="720px" dialog-confirm-btn-text="确定"
            :dialogVisible="dialogVisible"
            @onClose="dialogVisible = false"
            @onConfirm="signContract"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px;"
            >
                <div class="box_padding">
                    <div>
                        <span style="margin-right: 40px; font-size: 24px"
                            >服务日期</span
                        >
                        <span>
                            <span style="margin-right: 20px;">
                                <input
                                    type="radio"
                                    v-model="signContractParams.type"
                                    :value="1"
                                    checked="checked"
                                    style="margin-right: 10px;"
                                />
                                <i class="inputSpace">单次</i>
                            </span>
                            <span>
                                <input
                                    type="radio"
                                    v-model="signContractParams.type"
                                    :value="2"
                                    style="margin-right: 10px;"
                                />
                                <i class="inputSpace">周期</i>
                            </span>
                        </span>
                    </div>

                    <div style="margin-top: 16px; margin-left: 135px; margin-bottom: 18px;">
                        <CSSelect style="width: 240px;" height="40px" i-width="36px">
                            <el-date-picker
                                v-model="signContractParams.startDate"
                                type="date"
                                style="font-size: 24px; margin-bottom: 7px;"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择"
                                :clearable="false"
                                prefix-icon="el-icon-time"
                            >
                            </el-date-picker>
                        </CSSelect>
                        <template v-if="signContractParams.type === 2">
                            <div class="inline-gray"></div>
                            <CSSelect style="width: 240px" height="40px" i-width="36px">
                                <el-date-picker
                                    style="font-size: 24px; margin-bottom: 7px;"
                                    v-model="signContractParams.endDate"
                                    format="yyyy-MM-dd "
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="请选择"
                                    :clearable="false"
                                    prefix-icon="el-icon-time"
                                >
                                </el-date-picker>
                            </CSSelect>
                        </template>
                    </div>
                </div>

                <div class="box_padding">
                    <span style="margin-right: 40px; font-size: 24px"
                        >应付金额</span
                    >
                    <i style="font-size: 24px">
                        <input
                            type="text"
                            placeholder="1-999999"
                            style="
                                width: 130px;
                                height: 40px;
                                border: 1px solid #979797;
                                border-radius: 4px;
                            "
                            v-model="signContractParams.payableMoney"
                        />
                        元
                    </i>
                </div>
            </div>
        </CSDialog>

    </div>
</template>
<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination"
import { getadminQueryOrderUrl, signContractUrl } from "@/requestUrl";
import dayjs from "dayjs";
import { RED_DOT_TYPES } from "../../../common/commonConst.js";
import { getShowRedDot } from "@/utils/index.js";
import CSTable from "@/components/common/CSTable";

export default {
    name: "enterpriseServiceInquiry",
    components: {
        CSTable,
        CSSelect,
        Pagination,
        CSDialog,
    },
    data() {
        return {
            // 订单返回来的数据
            list: [],
            signOrderId: null,
            // 控制签约项目弹出框的显示与隐藏
            dialogVisible: false,

            signContractParams: {
                startDate: "",
                endDate: "",
                type: 1,
                payableMoney: "",
            },
        };
    },
    mounted() {
        this.$vc.on(this.$route.path, "pagination","page_event",(pageNo)=>{
            this.queryItem(pageNo);
        })
        this.queryItem();
    },
    methods: {
        // 查询询价中的数据
        queryItem(pageNo = 1,pageSize = 10) {
            this.$fly
                .post(getadminQueryOrderUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId, //区域id
                    state: 1, //1.询价中2.已签约3.不签约4.已退款
                    pageNo,
                    pageSize,
                })
                .then((res) => {
                    if(pageNo === 1){
                        this.$vc.emit(this.$route.path, "pagination","init",{
                            total: res.data.total || res.data.datas.length,
                            currentPage: pageNo,
                            pageSize: res.data.total ? pageSize : res.data.datas.length
                        })
                    }
                    this.list = res.data.datas;
                    this.serviceDate = res.data.datas.serviceDate;
                    getShowRedDot(this, RED_DOT_TYPES.ENT_INQUIRY);
                });
        },
        // 签约项目事件
        querySigning(id) {
            this.dialogVisible = true;
            this.signOrderId = id;
        },
        // 拒绝签约项目事件
        noQuerySigning(id) {
            this.$CSDialog.confirm({
                title: '提示',
                width: '480px',
                message: '确定拒签吗？',
                onConfirm: () => {
                    this.$fly
                        .post(signContractUrl, {
                            regionId: this.$vc.getCurrentRegion().communityId, //区域id 必填
                            id, //订单id 必填
                            sign: 0, //1.签约0.不签约 必填
                            // serviceDateType:this.MEETING_ROOM_TYPE.PUBLIC + this.MEETING_ROOM_TYPE.PRIVATE, //服务日期类型 1.单次2.周期
                            // serviceDate: this.startDate + this.endDate, //服务日期
                            // payableMoney: this.payableMoney, //应付金额 单位分
                        })
                        .then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("拒签成功");
                            this.$CSDialog.instance.closeDialog();
                            this.queryItem();
                        });
                }
            })
        },
        // 签约事件
        signContract() {
          if (this.signContractParams.payableMoney === '' || this.signContractParams.payableMoney == 0) {
            this.$vc.message('请填写应付金额');
            return ;
          }
            this.$fly
                .post(signContractUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId, //区域id 必填
                    id: this.signOrderId, //订单id 必填
                    sign: 1, //1.签约0.不签约 必填
                    serviceDateType: this.signContractParams.type, //服务日期类型 1.单次2.周期
                    serviceDate:
                        this.signContractParams.startDate +
                        (this.signContractParams.type == 2
                            ? "~" + this.signContractParams.endDate
                            : ""), //服务日期
                    payableMoney: this.signContractParams.payableMoney, //应付金额
                })
                .then((res) => {
                    console.log(res, "签约成功");
                    this.dialogVisible = false;
                    this.queryItem();
                });
        },
    },
};
</script>

<style scoped>
#enterpriseServiceInquiry .el-dialog__header {
    padding: 0;
    height: 0;
}
#enterpriseServiceInquiry .el-dialog__body {
    padding: 20px 0 20px 30px;
    color: #000;
}
#enterpriseServiceInquiry .el-dialog__footer {
    padding-top: 20px;
    text-align: center;
    border-top: 1px solid #999;
}
.signBox > el-dialog__footer {
    border-top: 1px solid #999;
}
.el-dialog .cancel {
    width: 80px;
    height: 40px;
    background: #ff9f00;
    color: #fff;
    font-size: 20px;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 6px;
    padding: inherit;
    margin-right: 30px;
}
.el-dialog .confirm {
    width: 80px;
    height: 40px;
    color: #fff;
    background: #1ab394;
    font-size: 20px;
    padding: inherit;
    box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
    border-radius: 6px;
}
#enterpriseServiceInquiry .el-card__body {
    padding: 3px 0 0px 0;
}
#enterpriseServiceInquiry .el-input--prefix .el-input__inner {
    width: 200px;
}
</style>

<style scoped>
.text-center {
    padding-bottom: 10px;
    vertical-align: middle;
}
.box_padding {
    margin-bottom: 10px;
}
i {
    font-style: normal;
}
.cs-dialog input[type="radio"] {
    /*width: 23px;
    height: 16px;*/
    cursor: pointer;
    position: relative;
    outline: none;
}
.el-dialog {
    border-radius: 5px !important;
}
.inputSpace {
    margin: 0 15px 0 3px;
    font-size: 24px;
}
.form-group {
    margin-left: 95px;
}

.priceBox div {
    display: inline-block;
    width: 160px;
}
.line {
    padding: 0 0px 0 60px;
}
</style>
